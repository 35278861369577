export const calculateSecondaryProductionsEndIndex = (numOfProduction: number, shouldTileCard = false): number => {
    const primaryProductionsCount = shouldTileCard ? 3 : 4
    const secondaryProductionsEndIndex = shouldTileCard ? 11 : 12

    if (numOfProduction <= primaryProductionsCount) {
        return primaryProductionsCount
    }
    if (numOfProduction >= secondaryProductionsEndIndex) {
        return secondaryProductionsEndIndex
    }

    const numOfProductionMinusPrimaryProductionCount = numOfProduction - primaryProductionsCount
    const secondaryProductionPerRow = 4
    const resultSecondaryProduciotionRowCount = Math.floor(
        numOfProductionMinusPrimaryProductionCount / secondaryProductionPerRow,
    )

    return primaryProductionsCount + resultSecondaryProduciotionRowCount * secondaryProductionPerRow
}
