import React, { ChangeEvent, useCallback, useContext, useEffect, useRef } from 'react'

import { Box, Icon, Text } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import debounce from 'lodash/debounce'

import { citySearchRegex, zipSearchRegex } from '@/constants/regex'
import { trackGeolocationEvent } from '@/utils/analytics'

import LocationFilterContext from '../context'
import { LocationEntry } from '../types'

import styles from './styles.module.scss'

const SET_QUERY_DEBOUNCE = 250

type LocationModalProps = {
    isShowing: boolean
    pageType?: string
    onSelect?: (location: LocationEntry, token: string) => void
    onInput?: (input: string) => void
    onReset?: () => void
}

type ModalContentProps = {
    results: LocationEntry[]
    onSelect?: (location: LocationEntry) => void
    onFocus?: () => void
}

export const ModalContent: React.FC<LocationModalProps & ModalContentProps> = ({
    isShowing,
    onSelect,
    onReset,
    onInput,
    onFocus,
    results,
}) => {
    const ref = useRef<HTMLInputElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const currentLocationClasses = classNames(styles.currentLocation, styles.searchEntry)

    // focus on open
    useEffect(() => {
        if (isShowing && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isShowing])

    // callback func for setQuery to debounce it client-side
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const setQueryCallback = useCallback(
        debounce((value: string) => {
            if (onInput) onInput(value)
        }, SET_QUERY_DEBOUNCE),
        [debounce, onInput],
    )

    const onInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) setQueryCallback(event.target.value)
    }

    const renderLocationEntry = (location: LocationEntry) => {
        return (
            <Box
                className={styles.searchEntry}
                key={location.placeId}
                data-testid="location-filter-result"
                onClick={() => {
                    if (onSelect) onSelect(location)
                }}
            >
                <Text as="span" size="lg" weight="regular" altFont>
                    {location.name}
                </Text>
            </Box>
        )
    }

    if (!isShowing) return null
    return (
        <div className={styles.modalContainer} data-testid="location-filter-modal" ref={ref}>
            <div className={styles.innerContainer}>
                <Icon className={styles.searchIcon} size="md" type="search" />
                <input
                    className={styles.searchInput}
                    data-testid="location-filter-search"
                    onChange={onInputHandler}
                    onClickCapture={onFocus}
                    placeholder="Search by City or ZIP"
                    ref={inputRef}
                />
            </div>
            <div className={styles.resultsContainer}>
                <Box className={currentLocationClasses} data-testid="current-location-result" onClick={onReset}>
                    <Icon className={styles.currentLocationIcon} size="md" type="location" />
                    <Text as="span" size="lg" weight="regular" altFont>
                        Current Location
                    </Text>
                </Box>
                {results.map(renderLocationEntry)}
            </div>
        </div>
    )
}

const LocationFilterModal: React.FC<LocationModalProps> = ({ isShowing, pageType, onSelect, onReset }) => {
    const context = useContext(LocationFilterContext)
    const {
        selectors: { token, query, results },
        dispatch: { setQuery, selectResult, setCurrentLocation },
    } = context

    // clear query on close
    useEffect(() => {
        if (!isShowing) {
            setQuery('')
        }
    }, [setQuery, isShowing])

    // track toggle on open
    useEffect(() => {
        if (isShowing) trackGeolocationEvent({ event_type: 'toggle', page_type: pageType })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isShowing])

    const onInputHandler = (input: string) => {
        if (!query) trackGeolocationEvent({ event_type: 'type', page_type: pageType })
        const trimmedInput = input.trim()
        if (zipSearchRegex.test(trimmedInput) || citySearchRegex.test(trimmedInput)) {
            setQuery(trimmedInput)
        }
    }

    const onSelectHandler = (location: LocationEntry) => {
        selectResult(location.placeId)
        trackGeolocationEvent({ event_type: 'select', page_type: pageType, search_term: location.name })
        if (onSelect) onSelect(location, token)
    }

    const onResetHandler = () => {
        setCurrentLocation()
        trackGeolocationEvent({ event_type: 'reset', page_type: pageType })
        if (onReset) onReset()
    }

    return (
        <ModalContent
            isShowing={isShowing}
            onSelect={onSelectHandler}
            onReset={onResetHandler}
            onInput={onInputHandler}
            onFocus={() => trackGeolocationEvent({ event_type: 'focus', page_type: pageType })}
            results={results}
        />
    )
}

export default LocationFilterModal
