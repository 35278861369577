import React, { useRef, useState } from 'react'

import { ButtonV2 } from '@vividseats/vivid-ui-kit'
import { CSSTransition } from 'react-transition-group'

import useWindowSize from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'

import TileTopPickProduction from '../tile-top-pick-production'
import { TileVariant } from '../tile-top-pick-production/type'
import TopPickProduction from '../top-pick-production'

import animationStyles from './animations.module.scss'
import styles from './styles.module.scss'
import { TileTopPicksProps, TopPicksShowMoreBtnText } from './types'
import { calculateSecondaryProductionsEndIndex } from './utils'

export const TileTopPicks: React.FC<TileTopPicksProps> = ({
    productions,
    handleProductionClick,
    carouselLocation,
    onShowExpandedLayoutClick,
    shouldShowMinPrice = true,
    shouldShowExpandedLayout = false,
    shouldTileCard = false,
}) => {
    const { type } = useWindowSize()

    const tileTopPickVariant = shouldTileCard ? TileVariant.Tile : TileVariant.Expand

    const [isShowSecondaryEnabled, setIsShowSecondaryEnabled] = useState(false)
    const secondaryProductionsContainerRef = useRef(null)

    const primaryProductionsEndIndex = shouldTileCard ? 3 : 4
    const secondaryProductionsStartIndex = shouldTileCard ? 3 : 4
    const secondaryProductionsEndIndex = calculateSecondaryProductionsEndIndex(productions.length, shouldTileCard)

    const primaryProductions = productions.slice(0, primaryProductionsEndIndex)
    const secondaryProductions = productions.slice(secondaryProductionsStartIndex, secondaryProductionsEndIndex)
    const hasPrimaryProductions = !!primaryProductions.length
    const hasSecondaryProduction = !!secondaryProductions.length
    const shouldDisplayShowMoreBtn = shouldShowExpandedLayout && hasSecondaryProduction

    const hasAndShouldShowSecondaryProduction = hasSecondaryProduction && isShowSecondaryEnabled
    const showMoreButtonText = isShowSecondaryEnabled
        ? TopPicksShowMoreBtnText.ShowLess
        : TopPicksShowMoreBtnText.ShowMore

    const handleToggleShowMoreEvents = () => {
        setIsShowSecondaryEnabled(!isShowSecondaryEnabled)
        onShowExpandedLayoutClick?.(showMoreButtonText)
    }
    // no more production can exist if not even a single primary production exists.
    if (!hasPrimaryProductions) return <></>

    return (
        <div data-testid="tile-top-pick">
            {type !== DeviceType.MOBILE && !shouldTileCard && (
                <div className={styles.expandTopPickPrimary} data-testid="expand-top-pick-primary-desktop-container">
                    {primaryProductions.map((prod, i) => (
                        <TopPickProduction
                            key={i}
                            production={prod}
                            productionIndex={i}
                            onClick={() => handleProductionClick?.(prod, i, carouselLocation)}
                            showMoreDates
                            shouldShowMinPrice={shouldShowMinPrice}
                            classes={{ itemLink: styles.expandTopPickPrimary__itemLink }}
                        />
                    ))}
                </div>
            )}
            {type === DeviceType.MOBILE && !shouldTileCard && (
                <div
                    className={styles.expandTopPickMobilePrimary}
                    data-testid="expand-top-pick-primary-mobile-container"
                >
                    {primaryProductions.map((prod, i) => (
                        <TileTopPickProduction
                            key={i}
                            production={prod}
                            classes={{ itemLink: styles.expandTopPickMobilePrimaryLinks }}
                            productionIndex={i}
                            onClick={() => handleProductionClick?.(prod, i, carouselLocation)}
                            shouldShowMinPrice={shouldShowMinPrice}
                        />
                    ))}
                </div>
            )}

            {shouldTileCard && (
                <div className={styles.tileTopPicksPrimary} data-testid="tile-top-pick-primary-container">
                    {primaryProductions.map((prod, i) => (
                        <TileTopPickProduction
                            key={i}
                            classes={{ itemLink: styles.tileTopPicksPrimaryLinks }}
                            production={prod}
                            productionIndex={i}
                            onClick={() => handleProductionClick?.(prod, i, carouselLocation)}
                            shouldShowMinPrice={shouldShowMinPrice}
                            variant={tileTopPickVariant}
                        />
                    ))}
                </div>
            )}

            <CSSTransition
                in={hasAndShouldShowSecondaryProduction}
                nodeRef={secondaryProductionsContainerRef}
                classNames={{ ...animationStyles }}
                timeout={140}
                unmountOnExit
                mountOnEnter
            >
                <div
                    ref={secondaryProductionsContainerRef}
                    className={styles.tileTopPicksSecondary}
                    data-testid="tile-top-pick-secondary-container"
                >
                    {secondaryProductions.map((prod, i) => {
                        /* offset by the amount of primary productions */
                        const secondaryProductionIndex = i + primaryProductionsEndIndex
                        return (
                            <TileTopPickProduction
                                classes={{ itemLink: styles.tileTopPicksSecondaryLinks }}
                                production={prod}
                                productionIndex={secondaryProductionIndex} /* offset by 3 primary production above */
                                key={secondaryProductionIndex}
                                onClick={() =>
                                    handleProductionClick?.(prod, secondaryProductionIndex, carouselLocation)
                                }
                                isSecondary
                                shouldShowMinPrice={shouldShowMinPrice}
                                variant={tileTopPickVariant}
                            />
                        )
                    })}
                </div>
            </CSSTransition>

            {shouldDisplayShowMoreBtn && (
                <div className={styles.showMoreLessBtnContainer}>
                    <ButtonV2
                        size="large"
                        importance="secondary"
                        onClick={handleToggleShowMoreEvents}
                        className={styles.showMoreLessBtn}
                        data-testid="tiled-show-more-event-btn"
                    >
                        {showMoreButtonText}
                    </ButtonV2>
                </div>
            )}
        </div>
    )
}

export default TileTopPicks
