import React, { useContext, useEffect, useRef, useState } from 'react'

import { Box, Icon } from '@vividseats/vivid-ui-kit'

import LocationFilterModal from '@/components/shared/filters/location-filter/components/location-filter-modal'
import UserLocationContext from '@/context/location/context'
import { PageType } from '@/types/app-types'

import { LocationFilterProvider } from './context'
import styles from './styles.module.scss'

const LocationFilter: React.FC<{ pageType?: string; overrideCity?: string; showCityState?: boolean }> = ({
    pageType = PageType.Home,
    overrideCity,
    showCityState = true,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const {
        selectors: { city, dropdownName },
        state: { hasSelectedLocation },
    } = useContext(UserLocationContext)

    const ref = useRef<HTMLDivElement>(null)

    // hide modal when clicked off
    useEffect(() => {
        const clickOffHandler = (event: any) => {
            if (ref.current && !ref.current.contains?.(event.target)) setIsModalOpen(false)
        }
        document.addEventListener('click', clickOffHandler)
        return () => document.removeEventListener('click', clickOffHandler)
    }, [])

    let dropdownDisplayLocation = city

    if (!hasSelectedLocation && !!overrideCity) {
        dropdownDisplayLocation = overrideCity
    } else if (showCityState) {
        dropdownDisplayLocation = dropdownName
    }

    const iconSize = pageType === PageType.Home ? 'xl' : 'md'

    return !!dropdownDisplayLocation ? (
        <div className={styles.locationFilter} ref={ref}>
            <Box
                className={styles.locationDisplay}
                data-testid="location-filter-toggle"
                onClickCapture={() => setIsModalOpen(!isModalOpen)}
            >
                <span>{dropdownDisplayLocation}</span>
                <Icon className={styles.locationDropdown} size={iconSize} type="dropdown" />
            </Box>
            <LocationFilterProvider>
                <LocationFilterModal
                    isShowing={isModalOpen}
                    pageType={pageType}
                    onSelect={() => setIsModalOpen(false)}
                    onReset={() => setIsModalOpen(false)}
                />
            </LocationFilterProvider>
        </div>
    ) : null
}

export default LocationFilter
