import React, { useEffect } from 'react'

import { Box, Link, Text, Pill } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import dayjs from 'dayjs'
import NextLink from 'next/link'
import { useInView } from 'react-intersection-observer'

import ProductionBadges from '@/components/shared/production-badges'
import { getPulsingBadgeLabel } from '@/components/shared/production-badges/utils'
import { useShouldOpenProductionInNewTab } from '@/components/shared/production-listings/hooks/should-open-in-new-tab/hook'
import { CATEGORY_IDS } from '@/constants'
import type { Production } from '@/types/app-types'
import { timeFromLocalString } from '@/utils/dates'
import { getMainPerformerId, getMainPerformerName, getProductionPath } from '@/utils/production'

import TopPickAsset from '../top-pick-asset'

import styles from './styles.module.scss'

interface TopPickProductionProps {
    production: Production
    productionIndex: number
    classes?: {
        itemLink?: string
        item?: string
        itemImage?: string
        itemTitle?: string
        detailsContainer?: string
        dateTime?: string
        date?: string
        time?: string
        place?: string
    }
    onClick?: () => void
    showMoreDates?: boolean
    shouldShowMinPrice: boolean | undefined
    hideVenue?: boolean
    onViewCallback?: (inView: boolean) => void
}

const TopPickProduction: React.FC<TopPickProductionProps> = ({
    production,
    productionIndex,
    classes,
    onClick,
    showMoreDates,
    shouldShowMinPrice,
    hideVenue = false,
    onViewCallback,
}) => {
    const { similarProductionCount } = production

    const onsaleFormatted = production.onsaleDate?.split('[')[0]
    const presale1Formatted = production.presale1Date?.split('[')[0]
    const presale2Formatted = production.presale2Date?.split('[')[0]

    const pulsingBadgeLabel = getPulsingBadgeLabel(onsaleFormatted, presale1Formatted, presale2Formatted)

    // similarProductionCount is a count of all productions by the main performer at a particular venue.
    // For sports, the production page we're linking to limits dates to productions by the main perfomer
    // *and an opponent** at that venue. So we hide the "more dates" pill for sports productions with
    // more than one performer.
    const isSports = production.categoryId === CATEGORY_IDS.SPORTS
    const hasMultiplePerformers = production.performers?.length > 1
    const isMoreDatesCountAccurate = !isSports || (isSports && !hasMultiplePerformers)
    const suffix = ` more date${similarProductionCount && similarProductionCount > 1 ? 's' : ''}`
    const moreDates =
        showMoreDates && isMoreDatesCountAccurate && similarProductionCount
            ? `+${similarProductionCount}${suffix}`
            : null

    const shouldOpenInNewTab = useShouldOpenProductionInNewTab()

    const { ref, inView } = useInView({ threshold: 1 })

    useEffect(() => {
        onViewCallback?.(inView)
    }, [inView, onViewCallback])

    return (
        <Link
            LinkComponent={NextLink}
            href={getProductionPath(production)}
            target={shouldOpenInNewTab ? '_blank' : '_self'}
            importance="none"
            className={classNames(styles.itemLink, classes?.itemLink)}
            data-testid={`top-picks-home-card-${productionIndex}`}
            onClick={onClick}
            legacyBehavior
        >
            <Box
                display="flex"
                mobileFlexDirection="column"
                tabletFlexDirection="column"
                className={classNames(styles.item, classes?.item)}
            >
                {/* Performer Image */}
                <div className={styles.topPickAssetContainer}>
                    <TopPickAsset
                        performerId={getMainPerformerId(production)}
                        performerName={getMainPerformerName(production)}
                        className={classNames(styles.topPickAsset, classes?.itemImage)}
                    />
                    <div className={styles.productionBadgeContainer}>
                        <ProductionBadges pulsingBadgeLabel={pulsingBadgeLabel} />
                    </div>
                </div>

                {/* Production Name */}
                <Text
                    altFont={true}
                    className={classNames(styles.itemTitle, classes?.itemTitle)}
                    truncate={true}
                    lineClamp={2}
                    data-testid={`top-picks-home-name-${productionIndex}`}
                >
                    {production.name}
                </Text>

                {/* Production Details */}
                <div
                    className={classNames(styles.detailsContainer, classes?.detailsContainer)}
                    data-testid={`more-details-${productionIndex}`}
                    ref={ref}
                >
                    <Box display="flex" className={classNames(styles.dateTime, classes?.dateTime)}>
                        {/* Production Date */}
                        <Text altFont={true} className={classNames(styles.date, classes?.date)}>
                            {dayjs(production.utcDate).format('MMMM DD')}
                        </Text>

                        {/* Production Time */}
                        <Text altFont={true} className={classNames(styles.time, classes?.time)}>
                            &nbsp;&#183;&nbsp;
                            {timeFromLocalString(production.localDate)}
                        </Text>
                        {moreDates && <Pill className={styles.moreDates}>{moreDates}</Pill>}
                    </Box>

                    {/* Venue Name */}
                    {!hideVenue && (
                        <Text
                            altFont={true}
                            className={classNames(styles.place, classes?.place)}
                            height="expanded"
                            truncate={true}
                            lineClamp={2}
                        >
                            {production.venue.name}
                        </Text>
                    )}
                    {shouldShowMinPrice && production.minPrice > 0 ? (
                        <Pill className={styles.minPrice}>${production.minPrice} +</Pill>
                    ) : null}
                </div>
            </Box>
        </Link>
    )
}

export default TopPickProduction
