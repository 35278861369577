import type { Production } from '@/types/app-types'

export enum TopPicksShowMoreBtnText {
    ShowMore = 'Show More Events',
    ShowLess = 'Show Fewer Events',
}

export type TopPicksScrollDirection = 'increment' | 'decrement'

export interface TileTopPicksProps {
    productions: Production[]
    handleProductionClick?: (production: Production, index: number, carouselLocation?: number) => void
    carouselLocation?: number
    onShowExpandedLayoutClick?: (clickText: TopPicksShowMoreBtnText) => void
    shouldShowMinPrice?: boolean
    shouldShowExpandedLayout?: boolean
    shouldTileCard?: boolean
}
