import React, { useCallback } from 'react'

import { Link, Text, Pill } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import NextLink from 'next/link'

import ProductionBadges from '@/components/shared/production-badges'
import { getPulsingBadgeLabel } from '@/components/shared/production-badges/utils'
import useWindowSize from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'
import type { Performer } from '@/types/app-types'
import { fromLocalDateString } from '@/utils/dates'
import {
    getMainPerformer,
    getMainPerformerId,
    getMainPerformerName,
    getPerformerUrl,
    getProductionPath,
} from '@/utils/production'

import TopPickAsset from '../top-pick-asset'

import styles from './styles.module.scss'
import { TileTopPickProductionProps, TileVariant } from './type'

export const getMainPerformerProductionCount = (performers: Performer[]): number => {
    if (!performers.length) return performers[0].productionCount || 0

    const masterPerformer = performers.filter((performer) => performer.master === true)
    return masterPerformer[0].productionCount || 0
}

const MinPrice = ({ productionId = 0, minPrice = '', isPill = false }) => {
    return isPill ? (
        <Pill className={styles.minPrice} data-testid={`min-price-pill-${productionId}`}>
            {minPrice}
        </Pill>
    ) : (
        <div className={styles.inlineMinPrice}>{minPrice}</div>
    )
}

const TileTopPickProduction: React.FC<TileTopPickProductionProps> = ({
    production,
    productionIndex,
    classes,
    onClick,
    isSecondary,
    shouldShowMinPrice,
    variant = TileVariant.Expand,
}) => {
    const { type } = useWindowSize()
    const isMobile = type === DeviceType.MOBILE

    // Performer
    const mainPerformer = getMainPerformer(production)
    const mainPerformerUrl = getPerformerUrl(mainPerformer)
    const productionPath = getProductionPath(production)

    // Events Near you
    const numMoreEventsNearYou = production.similarProductionCount || 1
    const moreEventsNearYouText = `${numMoreEventsNearYou} Event${numMoreEventsNearYou > 1 ? 's' : ''} Near You`

    // Min Price
    const shouldAndHasMinPrice = shouldShowMinPrice && production.minPrice > 0
    const minPriceValue = `$${production.minPrice} +`

    const totalProductionCount =
        production.performers.length === 0
            ? production.performers[0].productionCount
            : getMainPerformerProductionCount(production.performers)

    const mergedLinkClassname = classNames(classes?.itemLink, {
        [styles['secondaryItem']]: isSecondary,
        [styles['primaryItem']]: !isSecondary,
    })

    const mergedTopPickClassname = classNames({
        [styles['imageContainer']]: isSecondary,
        [styles['topPickAssetContainer']]: !isSecondary,
    })

    const onsaleFormatted = production.onsaleDate?.split('[')[0]
    const presale1Formatted = production.presale1Date?.split('[')[0]
    const presale2Formatted = production.presale2Date?.split('[')[0]

    const pulsingBadgeLabel = getPulsingBadgeLabel(onsaleFormatted, presale1Formatted, presale2Formatted)

    const expandLayoutItem = useCallback(
        () => (
            <div className={classNames(styles.productionItem, classes?.item)} data-testid="expand-top-pick-item">
                {/* Performer Image */}
                <div className={mergedTopPickClassname} data-testid="expand-top-pick-img">
                    <TopPickAsset
                        performerId={getMainPerformerId(production)}
                        performerName={getMainPerformerName(production)}
                        className={classNames(styles.topPickAsset, classes?.itemImage)}
                        isMainTile={!isSecondary && productionIndex === 0}
                    />
                    {!isSecondary && (
                        <div className={styles.productionBadgeContainerPrimary}>
                            <ProductionBadges pulsingBadgeLabel={pulsingBadgeLabel} />
                        </div>
                    )}
                </div>

                {/* Production Details Container */}
                <div
                    className={classNames(styles.detailsContainer, classes?.detailsContainer)}
                    data-testid="more-details"
                >
                    {/* Min Price - Primary DESKTOP */}
                    <div>
                        {!isSecondary && !isMobile && shouldAndHasMinPrice && (
                            <MinPrice productionId={production?.id} minPrice={minPriceValue} />
                        )}
                    </div>
                    {pulsingBadgeLabel && isSecondary && (
                        <div className={styles.productionBadgeContainerSecondary}>
                            <ProductionBadges pulsingBadgeLabel={pulsingBadgeLabel} />
                        </div>
                    )}

                    {/* Production Name */}
                    <Text
                        altFont={true}
                        className={classNames(styles.productionName, classes?.itemTitle)}
                        truncate={true}
                        lineClamp={2}
                        data-testid={`expand-top-picks-home-name-${productionIndex}`}
                    >
                        {production.name}
                    </Text>

                    {/* Production Date/Time */}
                    <Text
                        data-testid={`expand-top-picks-date-${productionIndex}`}
                        truncate={true}
                        lineClamp={1}
                        altFont
                        className={styles.productionDate}
                    >
                        {fromLocalDateString(production.localDate).format('ddd MMM D')}
                        <Text as="span" className={styles.productionTime}>
                            &nbsp;•&nbsp;
                            {fromLocalDateString(production.localDate).format('h:mma')}
                        </Text>
                    </Text>

                    {/* Expand toppick only!!! */}
                    <div
                        data-testid={`expand-top-picks-venue-${productionIndex}`}
                        className={styles.productionVenueName}
                    >
                        <div className={styles.venueName}>{production.venue.name}</div>
                        {shouldAndHasMinPrice && (
                            <div className={styles.inlineMinPriceContainer}>
                                &nbsp;•&nbsp;
                                <MinPrice productionId={production?.id} minPrice={minPriceValue} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [isMobile, isSecondary, minPriceValue, production?.id, variant],
    )

    const tileLayoutItem = useCallback(
        () => (
            <div
                className={classNames(styles.productionItem, styles.tileVariant, classes?.item)}
                data-testid="tile-top-pick-item"
            >
                {/* Performer Image */}
                <div className={mergedTopPickClassname} data-testid="tile-top-pick-img">
                    <TopPickAsset
                        performerId={getMainPerformerId(production)}
                        performerName={getMainPerformerName(production)}
                        className={classNames(styles.topPickAsset, classes?.itemImage)}
                        isMainTile={!isSecondary && productionIndex === 0}
                    />
                    <div>
                        {/* Min Price - Primary Mobile */}
                        {!isSecondary && isMobile && shouldAndHasMinPrice && (
                            <MinPrice productionId={production?.id} minPrice={minPriceValue} isPill />
                        )}
                    </div>
                </div>

                {/* Production Details Container */}
                <div
                    className={classNames(styles.detailsContainer, classes?.detailsContainer)}
                    data-testid="more-details"
                >
                    {/* Min Price - Primary DESKTOP */}
                    <div>
                        {!isSecondary && !isMobile && shouldAndHasMinPrice && (
                            <MinPrice productionId={production?.id} minPrice={minPriceValue} isPill />
                        )}
                    </div>

                    {/* Production Name */}
                    <Text
                        altFont={true}
                        className={classNames(styles.productionName, classes?.itemTitle)}
                        truncate={true}
                        lineClamp={2}
                        data-testid={`tile-top-picks-home-name-${productionIndex}`}
                    >
                        {production.name}
                    </Text>

                    <Text className={styles.numMoreEventsNearYou}>{moreEventsNearYouText}</Text>
                    {totalProductionCount && (
                        <Text className={styles.performerEventCounts}>{totalProductionCount} Events </Text>
                    )}
                    {isSecondary && shouldAndHasMinPrice && (
                        <MinPrice productionId={production?.id} minPrice={minPriceValue} isPill />
                    )}
                    <ProductionBadges data-testid="prod-badges" pulsingBadgeLabel={pulsingBadgeLabel} />
                </div>
            </div>
        ),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [isMobile, isSecondary, minPriceValue, production?.id, variant],
    )

    return variant === TileVariant.Tile ? (
        <Link
            LinkComponent={NextLink}
            href={mainPerformerUrl}
            target="_self"
            className={mergedLinkClassname}
            data-testid={`tile-top-picks-home-card-${isSecondary ? 'secondary' : 'primary'}-${productionIndex}`}
            onClick={onClick}
            legacyBehavior
        >
            {tileLayoutItem()}
        </Link>
    ) : (
        <Link
            LinkComponent={NextLink}
            href={productionPath}
            target="_self"
            className={mergedLinkClassname}
            data-testid={`expand-top-picks-home-card-${isSecondary ? 'secondary' : 'primary'}-${productionIndex}`}
            onClick={onClick}
            legacyBehavior
        >
            {expandLayoutItem()}
        </Link>
    )
}

export default TileTopPickProduction
