import React, { useContext, useMemo } from 'react'

import { ExternalOrLazyImage } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import { PATHS } from '@/constants'
import catalogAssetContext from '@/context/catalog-asset'

import styles from './styles.module.scss'

interface TopPickAssetProps {
    performerId: number
    performerName: string
    className?: string
    isMainTile?: boolean
}

const TopPickAsset: React.FC<TopPickAssetProps> = ({ performerId, performerName, className, isMainTile }) => {
    const {
        selectors: [, imagesByPerformerId],
    } = useContext(catalogAssetContext)

    const desktopImage = imagesByPerformerId[performerId]?.desktop
    const mobileImage = imagesByPerformerId[performerId]?.mobile
    const externalImage = imagesByPerformerId[performerId]?.external
    const externalImageAlt = `${performerName} Image`
    const fallbackImage = imagesByPerformerId[performerId]?.fallbackUrl
    const externalImageUrl: string | undefined = useMemo(() => {
        if (!externalImage) return undefined
        if (isMainTile) {
            return `${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}${PATHS.TOP_PICK_EXTERNAL_IMAGE_WIDE_PATH}${externalImage}`
        }
        return `${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}${PATHS.TOP_PICK_EXTERNAL_IMAGE_MID_PATH}${externalImage}`
    }, [externalImage, isMainTile])

    const fallbackUrl: string | undefined = useMemo(
        () =>
            fallbackImage
                ? `${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}${PATHS.TOP_PICK_EXTERNAL_IMAGE_MID_PATH}${fallbackImage}`
                : undefined,
        [fallbackImage],
    )

    const topPickAssetClassNames = classNames(styles.itemImage, className)

    return (
        <ExternalOrLazyImage
            className={topPickAssetClassNames}
            externalImage={externalImageUrl}
            externalImageAlt={externalImageAlt}
            fallbackUrl={fallbackUrl}
            additionalResponsiveProps={{
                mobile: {
                    url: mobileImage || desktopImage,
                },
                desktop: {
                    url: desktopImage || mobileImage,
                },
            }}
        />
    )
}

export default TopPickAsset
