import { Production } from '@/types/app-types'
export enum TileVariant {
    Expand = 'expand',
    Tile = 'tile',
}

export type TileTopPickProductionProps = {
    production: Production
    productionIndex: number
    classes?: {
        itemLink?: string
        item?: string
        itemImage?: string
        itemTitle?: string
        detailsContainer?: string
        dateTime?: string
        date?: string
        time?: string
        place?: string
    }
    onClick?: () => void
    isSecondary?: boolean
    shouldShowMinPrice?: boolean
    minPriceVariant?: 'pill' | 'inline'
    variant?: TileVariant
}
