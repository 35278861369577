export const nonLatin1Regex = /[^\x00-\xFF]/
export const symbolAndWhiteSpaceRegex = /[^A-Za-z0-9]/g

/**
 * Regex for validating phone numbers. The accepted phone number formats are as follows:
 *
 * 1 800 5551212
 * 800 555 1212
 * 8005551212
 * 18005551212
 * +1800 555 1212 extension65432
 * 800 5551212 ext3333
 */
export const phoneNumberRegex =
    /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})\s*(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+)\s*)?$/

/**
 * Regex for determining whether the location input is valid to make a request to geolocation service.
 *
 * Rules:
 * Zip: Should be 5 digits long before sending a request.
 * City: Should only send request if 3 or more characters are entered.
 */
export const zipSearchRegex = /^\d{5,}$/
export const citySearchRegex = /^\w\D{2,}$/

// From vivid-web: https://github.com/VividSeats/vivid-web/blob/dc72705c42f71719bc141e127251f8ee1b99b5c1/src/main/es6/common/utils/VividValidation.js#L22
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
