import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { useQuery } from '@/api'
import { GEOLOCATION_ENDPOINT, HEADERS } from '@/constants'
import UserLocationContext from '@/context/location'
import { COUNTRIES } from '@/context/location/constants'

import {
    LocationFilterContextType,
    LocationFilterData,
    LocationFilterDispatch,
    LocationFilterProviderProps,
    LocationFilterSelectors,
    LocationFilterState,
} from '../types'

import { querySelector, resultsSelector, tokenSelector } from './selectors'

const defaultContextValue: LocationFilterContextType = {
    state: {
        token: '',
        query: '',
    },
    selectors: {
        results: [],
        token: '',
        query: '',
    },
    dispatch: {
        setQuery: () => null,
        selectResult: () => null,
        setCurrentLocation: () => null,
    },
}

const LocationFilterContext = createContext<LocationFilterContextType>(defaultContextValue)
const { Provider } = LocationFilterContext

export const LocationFilterProvider: React.$FC<LocationFilterProviderProps> = ({ children }) => {
    const [query, setQuery] = useState('')
    const [token, setToken] = useState('')
    const {
        dispatch: { setLocation, resetLocation },
    } = useContext(UserLocationContext)

    const updateQuery = useCallback((query: string) => {
        setQuery(query)
    }, [])

    const selectResult = useCallback(
        (placeId: string) => {
            if (token && placeId) setLocation(token, placeId)
            setToken('')
            setQuery('')
        },
        [setLocation, token],
    )

    const setCurrentLocation = useCallback(() => {
        resetLocation()
        setToken('')
        setQuery('')
    }, [resetLocation])

    const shouldFetchNewToken = !!query && !token
    const { fetching: fetchingToken, data: tokenData } = useQuery<{ uuid: string }>({
        endpoint: `${GEOLOCATION_ENDPOINT}/search/token`,
        enabled: !!query && !token,
    })
    if (shouldFetchNewToken && !fetchingToken && !!tokenData) {
        setToken(tokenData.uuid)
    }

    const { fetching, data, error } = useQuery<LocationFilterData>({
        // add garbage parameter to bust the cache
        endpoint: `${GEOLOCATION_ENDPOINT}/search/query`,
        params: { queryStr: query, c: '2' },
        enabled: !!query && !!token,
        initialData: [],
        headers: { [HEADERS.X_PLACES_SESSION_TOKEN]: token },
    })

    const trimmedData: LocationFilterData = useMemo(() => {
        return data?.filter((loc) => !COUNTRIES.includes(loc.name)) || []
    }, [data])

    const contextValue = useMemo(() => {
        const state: LocationFilterState = {
            fetching,
            data: query ? trimmedData : [],
            error,
            token,
            query,
        }

        const selectors: LocationFilterSelectors = {
            results: resultsSelector(state),
            token: tokenSelector(state),
            query: querySelector(state),
        }

        const dispatch: LocationFilterDispatch = {
            setQuery: updateQuery,
            selectResult: selectResult,
            setCurrentLocation: setCurrentLocation,
        }

        return { state, selectors, dispatch }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [fetching, trimmedData, error, query, token])

    return <Provider value={contextValue}>{children}</Provider>
}

export default LocationFilterContext
