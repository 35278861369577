import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { ArrowForwardIcon, Box, CardSkeletonLoader, HorizontalScroll, Icon, Text } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import Link from 'next/link'

import UserLocationFilter from '@/components/shared/filters/location-filter'
import { PATHS } from '@/constants'
import { CatalogAssetProvider } from '@/context/catalog-asset'
import UserLocationContext from '@/context/location'
import { AnalyticsSubIds } from '@/context/utils/analytics'
import { ButtonWithCustomChildren } from '@/design-system/components/button'
import { useAnalytics } from '@/hooks/use-analytics'
import useWindowSize from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'
import type { Production } from '@/types/app-types'
import { PageType } from '@/types/app-types'
import { saveLastItemListName, trackHomePageEvent } from '@/utils/analytics'
import { ClickLocation } from '@/utils/analytics/types'
import { getMainPerformerId } from '@/utils/production'

import { TileTopPicks } from './components/tile-top-pick/component'
import type { TopPicksScrollDirection, TopPicksShowMoreBtnText } from './components/tile-top-pick/types'
import TopPickProduction from './components/top-pick-production'
import styles from './styles.module.scss'
import type { ScrollableArrowsProps, TopPicksProps } from './types'

const { HOME_PAGE_TOP_PICKS_EXPAND_CLICK_LOCATION, HOME_PAGE_TOP_PICKS_SCROLL_CLICK_LOCATION } = ClickLocation

export const ScrollableArrows: React.FC<ScrollableArrowsProps> = ({
    isDecrementScrollDisabled,
    isIncrementScrollDisabled,
    handleScrollClick,
}) => {
    return (
        <>
            <ButtonWithCustomChildren
                className={styles.topPicksArrow}
                disabled={isDecrementScrollDisabled}
                color="neutral"
                size="small"
                variant="outlined"
                onClick={() => handleScrollClick('decrement')}
                data-testid="topPicksLeftArrowButton"
            >
                <Icon type="carat-left" size="sm" />
            </ButtonWithCustomChildren>

            <ButtonWithCustomChildren
                className={styles.topPicksArrow}
                disabled={isIncrementScrollDisabled}
                color="neutral"
                size="small"
                variant="outlined"
                onClick={() => handleScrollClick('increment')}
                data-testid="topPicksRightArrowButton"
            >
                <Icon type="carat-right" size="sm" />
            </ButtonWithCustomChildren>
        </>
    )
}

const FindMoreEventLink: React.FC<{ classname: string }> = ({ classname }) => (
    <Link href={PATHS.SEARCH} target="_self" legacyBehavior>
        <Box display="flex">
            <Text altFont={true} className={classname} data-testid="top-picks-home-more-events-link">
                Find More Events&nbsp;
            </Text>
            <ArrowForwardIcon fontSize="1.5rem" />
        </Box>
    </Link>
)

const TopPicks: React.FC<TopPicksProps> = ({
    shouldShowLoader,
    productions: allProductions,
    title = 'Our top picks this week',
    desktopHasFindMoreEventsLink = true,
    mobileHasFindMoreEventsLink = false,
    desktopRowCount,
    classes,
    trackingCarouselLocation,
    showMoreDates,
    hideVenue,
    filterComponent,
    onProductionClick,
    shouldShowMinPrice,
    shouldShowExpandedLayout,
    shouldShowScrollableArrows,
    shouldTileCard,
    shouldAboveTheFold,
    shouldShowEventsUnder100,
    activeDateFilter,
    shouldShowUserLocation = true,
    trackingClickLocation = ClickLocation.HOME_PAGE_TOP_PICKS,
    trackingPageType = PageType.Home,
}) => {
    const {
        selectors: { city },
    } = useContext(UserLocationContext)
    const { analyticsId } = useAnalytics(AnalyticsSubIds.TopPicks)
    const { type } = useWindowSize()

    const [isScrollDecrementDisabled, setIsScrollDecrementDisabled] = useState(true)
    const [isScrollIncrementDisabled, setIsScrollIncrementDisabled] = useState(false)
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const numOfProductionsToScroll = useMemo(() => {
        if (shouldShowExpandedLayout) {
            if (type === DeviceType.MOBILE) return 1
            return 3
        }
        return 4
    }, [type, shouldShowExpandedLayout])

    const getRowScrollWidth = useCallback(() => scrollContainerRef.current?.clientWidth || 0, [])

    const rowCount: number = useMemo(() => {
        if (desktopRowCount) return desktopRowCount
        return 1
    }, [desktopRowCount])

    const productionEndIndex: number = useMemo(() => {
        if (shouldShowExpandedLayout || shouldShowScrollableArrows || shouldTileCard) return allProductions.length
        return rowCount * numOfProductionsToScroll
    }, [
        shouldShowExpandedLayout,
        shouldShowScrollableArrows,
        shouldTileCard,
        allProductions.length,
        rowCount,
        numOfProductionsToScroll,
    ])

    const productions: Production[] = useMemo(
        () =>
            allProductions
                .sort((a, b) => {
                    const aDate = new Date(a.utcDate)
                    const bDate = new Date(b.utcDate)
                    return aDate.getTime() - bDate.getTime()
                })
                .slice(0, productionEndIndex),
        [allProductions, productionEndIndex],
    )

    // Main performer Ids from up to 8 first productions
    const mainPerformerIds: number[] = useMemo(
        () => productions.map((production: Production) => getMainPerformerId(production)),
        [productions],
    )

    const resetScrollState = useCallback(() => {
        scrollContainerRef?.current?.scrollTo?.({
            left: 0,
            behavior: 'smooth',
        })
    }, [])

    // If the productions change scroll back to left or collapse show more events
    useEffect(() => {
        if (shouldShowScrollableArrows) resetScrollState()
    }, [city, allProductions, activeDateFilter, shouldShowScrollableArrows, resetScrollState])

    const handleProductionClick = (production: Production, index: number, trackingCarouselLocation = 1) => {
        trackHomePageEvent({
            page_type: trackingPageType,
            click_location: trackingClickLocation,
            click_text: production.name,
            click_tile_location: index + 1,
            carousel_location: trackingCarouselLocation,
        })

        if (onProductionClick) {
            onProductionClick()
        }

        saveLastItemListName(analyticsId, production.id)
    }

    const handleScrollableArrowsClick = (direction: TopPicksScrollDirection) => {
        trackHomePageEvent({
            click_location: HOME_PAGE_TOP_PICKS_SCROLL_CLICK_LOCATION,
            click_text: direction,
        })
    }

    const handleShowExpandedLayoutClick = (click_text: TopPicksShowMoreBtnText) => {
        trackHomePageEvent({
            click_location: HOME_PAGE_TOP_PICKS_EXPAND_CLICK_LOCATION,
            click_text,
        })
    }

    const trackScrollableArrowClick = (direction: TopPicksScrollDirection) => handleScrollableArrowsClick?.(direction)

    const handleScrollClick = (scrollType: TopPicksScrollDirection) => {
        const rowScrollWidth = getRowScrollWidth()
        scrollContainerRef?.current?.scrollTo?.({
            left:
                scrollType === 'increment'
                    ? scrollContainerRef.current.scrollLeft + rowScrollWidth
                    : scrollContainerRef.current.scrollLeft - rowScrollWidth,
            behavior: 'smooth',
        })

        trackScrollableArrowClick(scrollType)
    }

    const handleProductionView = (inView: boolean, productionIndex: number) => {
        if (shouldShowScrollableArrows) {
            const isFirstProduction = productionIndex === 0
            const isLastProduction = productionIndex === productions.length - 1
            isFirstProduction && setIsScrollDecrementDisabled(inView)
            isLastProduction && setIsScrollIncrementDisabled(inView)
        }
    }

    return (
        <div
            className={classNames(styles.topPicksContainer, classes?.topPicksContainer, {
                [styles.aboveTheFold]: shouldAboveTheFold,
            })}
            data-testid={shouldShowEventsUnder100 ? 'top-picks-under-100-home-container' : `top-picks-home-container`}
        >
            {/* Header Row */}
            <Box
                display="flex"
                mobileJustify="between"
                tabletJustify="between"
                mobileAlign="center"
                tabletAlign="center"
                className={classNames(styles.headerContainer, classes?.headerContainer)}
            >
                {/* Header */}
                <Text
                    as="h2"
                    altFont={true}
                    className={classNames(styles.headerText, classes?.headerText)}
                    data-testid="top-picks-title"
                >
                    {title}
                    {!!city && shouldShowUserLocation && (
                        <>
                            {' in '}
                            <UserLocationFilter />
                        </>
                    )}
                </Text>

                {/* Find More Events */}
                <Box
                    className={styles.moreTopPicks}
                    desktopDisplay={desktopHasFindMoreEventsLink ? 'flex' : 'none'}
                    mobileDisplay={mobileHasFindMoreEventsLink ? 'flex' : 'none'}
                    data-testid="more-top-picks"
                >
                    {shouldShowScrollableArrows ? (
                        <ScrollableArrows
                            isDecrementScrollDisabled={isScrollDecrementDisabled}
                            isIncrementScrollDisabled={isScrollIncrementDisabled}
                            handleScrollClick={handleScrollClick}
                        />
                    ) : (
                        <FindMoreEventLink classname={classNames(styles.moreTopPicksText, classes?.moreTopPicksText)} />
                    )}
                </Box>
            </Box>
            {filterComponent}
            {/* Production Rows */}
            <CardSkeletonLoader
                className={classNames(styles.loader, classes?.loader)}
                isLoading={shouldShowLoader}
                descriptorLineCount={4}
            >
                <CatalogAssetProvider initialParams={{ resource: 'PERFORMER', resourceId: mainPerformerIds }}>
                    {shouldShowExpandedLayout || shouldTileCard ? (
                        <TileTopPicks
                            productions={allProductions}
                            handleProductionClick={handleProductionClick}
                            carouselLocation={trackingCarouselLocation}
                            onShowExpandedLayoutClick={handleShowExpandedLayoutClick}
                            shouldShowExpandedLayout={shouldShowExpandedLayout}
                            shouldTileCard={shouldTileCard}
                            shouldShowMinPrice={shouldShowMinPrice}
                        />
                    ) : (
                        <>
                            {[...Array(rowCount)].map((_, rowIndex) => {
                                const mobileAndTabletDisplay = rowIndex === 0 ? 'block' : 'none'
                                const rowStartIndex = rowIndex * numOfProductionsToScroll
                                const rowEndIndex = rowStartIndex + numOfProductionsToScroll
                                const rowProductions: Production[] = shouldShowScrollableArrows
                                    ? productions
                                    : productions.slice(rowStartIndex, rowEndIndex)

                                return rowProductions.length > 0 ? (
                                    <Box
                                        key={rowIndex}
                                        display={mobileAndTabletDisplay}
                                        desktopDisplay="block"
                                        mobileDisplay="block"
                                        marginBottom={5}
                                        data-testid={`regular-top-pick-${rowIndex}`}
                                    >
                                        <HorizontalScroll
                                            scroll={false}
                                            className={classNames(styles.scrollContainer, classes?.scrollContainer)}
                                            classNameForHsWrapper={classNames(
                                                styles.scrollWrapper,
                                                classes?.scrollWrapper,
                                            )}
                                            scrollRef={scrollContainerRef}
                                        >
                                            {rowProductions.map((production, productionIndex) => (
                                                <TopPickProduction
                                                    key={productionIndex}
                                                    production={production}
                                                    productionIndex={productionIndex}
                                                    classes={classes}
                                                    onClick={() =>
                                                        handleProductionClick?.(
                                                            production,
                                                            productionIndex,
                                                            trackingCarouselLocation,
                                                        )
                                                    }
                                                    showMoreDates={showMoreDates}
                                                    shouldShowMinPrice={shouldShowMinPrice}
                                                    hideVenue={hideVenue}
                                                    onViewCallback={(inView) =>
                                                        handleProductionView(inView, productionIndex)
                                                    }
                                                />
                                            ))}
                                        </HorizontalScroll>
                                    </Box>
                                ) : null
                            })}
                        </>
                    )}
                </CatalogAssetProvider>
            </CardSkeletonLoader>
        </div>
    )
}

export default TopPicks
